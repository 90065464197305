import { customAlphabet } from 'nanoid'

import { Organization } from '~/api/organizations'

export const generateId = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10)

export function wait(ms = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const defaultNumberFormattingOptions: Intl.NumberFormatOptions = {
  // use the more precise result of maximumFractionDigits vs. maximumSignificantDigits
  roundingPriority: 'morePrecision',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  maximumSignificantDigits: 2,
}

// This allows us to re-use the default formatter in most cases
const defaultNumberFormatter = new Intl.NumberFormat('en-US', defaultNumberFormattingOptions)

export function formatNumber(value: number | undefined, options?: Intl.NumberFormatOptions) {
  if (value === undefined) {
    return ''
  }

  if (!options) {
    return defaultNumberFormatter.format(value)
  }

  return new Intl.NumberFormat('en-US', options).format(value)
}

function shouldHaveScientificFormat(value: number) {
  return (Math.abs(value) !== 0 && Math.abs(value) < 0.01) || Math.abs(value) >= 10000
}

const defaultScientificNumberFormatter = new Intl.NumberFormat('en-US', {
  ...defaultNumberFormattingOptions,
  notation: 'scientific',
})

export function formatNumberScientific(
  value: number | undefined,
  options?: Partial<{
    minimumFractionDigits: number
    maximumFractionDigits: number
    minimumSignificantDigits: number
    maximumSignificantDigits: number
    forceScientificFormat: boolean
  }>
) {
  if (value === undefined) {
    return ''
  }
  const { forceScientificFormat, ...formatOptions } = options ?? {}
  const isScientific = shouldHaveScientificFormat(value) || forceScientificFormat

  if (!options && isScientific) {
    return defaultScientificNumberFormatter.format(value)
  }

  if (!options) {
    return defaultNumberFormatter.format(value)
  }

  const customFormatOptions = Object.assign({}, defaultNumberFormattingOptions, formatOptions)

  if (isScientific) {
    Object.assign(customFormatOptions, { notation: 'scientific' })
  }

  return new Intl.NumberFormat('en-US', customFormatOptions).format(value)
}

export function shouldAxesHaveScientificFormat(axesValues: number[]) {
  return axesValues.some((value) => shouldHaveScientificFormat(value))
}

export const { format: formatDate } = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
})

export const { format: formatTime } = new Intl.DateTimeFormat(undefined, {
  hour: '2-digit',
  minute: '2-digit',
})

export const { format: formatDateTime } = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
})

export function formatTimeInterval(
  mostRecentDate: Date,
  leastRecentDate: Date
): { value: number; label: Intl.RelativeTimeFormatUnit } {
  const differenceInMinutes = Math.round(
    (mostRecentDate.valueOf() - leastRecentDate.valueOf()) / 60000
  )

  if (differenceInMinutes < 60) {
    return { value: differenceInMinutes, label: 'minutes' }
  }

  const differenceInHours = Math.round(differenceInMinutes / 60)

  if (differenceInHours < 24) {
    return { value: differenceInHours, label: 'hours' }
  }

  const differenceInDays = Math.round(differenceInHours / 24)

  if (differenceInDays < 30) {
    return { value: differenceInDays, label: 'days' }
  }

  const differenceInMonths = Math.round(differenceInDays / 30)

  if (differenceInMonths < 12) {
    return { value: differenceInMonths, label: 'months' }
  }

  const differenceInYears = Math.round(differenceInMonths / 12)

  return { value: differenceInYears, label: 'years' }
}

const shortRelativeTime = new Intl.RelativeTimeFormat('en', { style: 'narrow' })

const longRelativeTime = new Intl.RelativeTimeFormat('en', { style: 'long' })

export function formatRelativeTime(value?: Date, style: 'narrow' | 'long' = 'narrow') {
  if (value === undefined) {
    return
  }

  const relativeTimeFormatter = style === 'narrow' ? shortRelativeTime : longRelativeTime

  const timeInterval = formatTimeInterval(new Date(), value)

  return relativeTimeFormatter.format(-timeInterval.value, timeInterval.label)
}

const andListFormatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
})

const orListFormatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'disjunction',
})

export function formatList(values: string[], options?: { type: 'conjunction' | 'disjunction' }) {
  if (options?.type === 'disjunction') {
    return orListFormatter.format(values)
  } else {
    return andListFormatter.format(values)
  }
}

/**
 * Capitalize the first letter of a string
 *
 * @export
 * @param str - The string to capitalize
 * @return The capitalized string
 * @example
 * capitalize('hello') // 'Hello'
 */
export function capizalizeString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Does nothing
 */
export function noop() {
  // do nothing
}

/**
 * Clamps number within the inclusive lower and upper bounds.
 *
 * @example
 * clamp(10, 0, 20)
 * > 10
 *
 * clamp(-100, 0, 20)
 * > 0
 *
 * clamp(100, 0, 20)
 * > 20
 *
 */
export function clamp(value: number, min = -Infinity, max = Infinity) {
  return Math.min(max, Math.max(min, value))
}

/**
 * Calculate the sum of all numbers in an array.
 *
 * @example
 * arraySum([1, 2, 3])
 * > 6
 *
 */
export function arraySum(array: number[]) {
  let result = 0
  for (const item of array) {
    result += item
  }
  return result
}

/**
 * Checks if code is running in Safari.
 */
export function isSafari() {
  // GestureEvent is a proprietary API that only Safari implemented as is not on a standards track.
  // https://developer.mozilla.org/en-US/docs/Web/API/GestureEvent
  return 'GestureEvent' in window
}

export const isChromium = (() => {
  if ('userAgentData' in navigator) {
    // @ts-expect-error
    return navigator.userAgentData.brands.some(({ brand }) => brand === 'Chromium')
  }
  return false
})()

/**
 * Creates an instance of URLSearchParams but handles arrays, undefined and null
 */
export function createSearchParams(init: Record<string, string | string[] | undefined | null>) {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(init)) {
    if (typeof value === 'string') {
      params.set(key, value)
    } else if (Array.isArray(value)) {
      for (const id of value) {
        params.append(key, id)
      }
    }
  }

  return params
}

export type Member = Organization['members'][number] & { isSelf: boolean }

export function getMemberListsByRole<T extends Partial<Member>>(rawMembers: T[]) {
  const members: T[] = []
  const owners: T[] = []
  const suspended: T[] = []

  for (const member of rawMembers) {
    if (member.is_suspended) {
      suspended.push(member)
      continue
    }
    if (member.member_role === 'owner') {
      owners.push(member)
    }

    if (member.member_role === 'member') {
      members.push(member)
    }
  }

  return { members, owners, suspended }
}

export function stripExtension(name: string) {
  return name.replace(/\.[^/.]+$/, '')
}

export async function* streamAsyncIterator<T = unknown>(stream: ReadableStream<T>) {
  // Get a lock on the stream
  const reader = stream.getReader()

  try {
    while (true) {
      // Read from the stream
      const { done, value } = await reader.read()
      // Exit if we're done
      if (done) return
      // Else yield the chunk
      yield value
    }
  } finally {
    reader.releaseLock()
  }
}
