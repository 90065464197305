import { setUser } from '@sentry/vue'
import debug from 'debug'
import merge from 'lodash/merge'
import { reactive } from 'vue'

import { api } from '../api/quantistry'

// @TODO(API) put this in the API schema?
export type UserType = 'enterprise' | 'community' | undefined
type Account = Awaited<ReturnType<typeof api.account.get>> & { type: UserType }
type AccountUpdateRequest = Parameters<typeof api.account.update>[0]['requestBody']

const log = debug('quantistry:store')

class Store {
  state = reactive({
    isAuthenticated: false,
    user: {} as Account,
  })

  //TODO:remove userType once /account returns the user type
  async initialize(userType: UserType = 'community') {
    log('initializing')
    try {
      const user = await this.fetchUser()
      setUser({ email: user.email, id: user.id, type: userType })
      this.state.user = { ...user, type: userType }
      this.state.isAuthenticated = true
    } catch {
      this.state.isAuthenticated = false
    }
    log('initialized')
  }

  async fetchUser() {
    const user = await api.account.get()
    return user
  }

  async updateUser(userData: AccountUpdateRequest) {
    // keep type, because it's not present in /account
    const { type } = this.state.user

    // optimistic updates
    this.state.user = merge({}, this.state.user, userData)

    const user = await api.account.update({ requestBody: userData })

    this.state.user = { ...user, type }
  }
}

const store = new Store()

export function useStore() {
  return store
}
