import * as Sentry from '@sentry/vue'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { createApp } from 'vue'

import { createListOrganizationsQuery, getUserType } from './api/organizations'
import { createListProjectsQuery } from './api/projects'
import App from './App.vue'
import config from './config'
import { router } from './router'
import { UserType, useStore } from './store/useStore'
import { registerMatomo } from './utils/tracking'

async function main() {
  const queryClient = new QueryClient()
  const store = useStore()
  const app = createApp(App)

  if (!config.isDev) {
    Sentry.init({
      app,
      dsn: 'https://66fa3840e16c48ed8dd73384e5c2d7fa@o1343746.ingest.sentry.io/6618978',
      environment: config.deployment,
      integrations: [Sentry.browserTracingIntegration({ router })],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.25,
      release: config.release,
    })
  }

  // Prefetch queries used in the AppShell to improve first load performance
  // by avoiding some request waterfalls
  queryClient.prefetchQuery(createListProjectsQuery({ isFavorite: true }))

  //TODO:remove once /account returns the user type
  let userType
  try {
    const organizations = await queryClient.fetchQuery(createListOrganizationsQuery())
    userType = getUserType(organizations) as UserType
  } catch (err) {
    console.error(err)
  }

  // fetch user
  await store.initialize(userType)

  app.use(router)
  VueQueryPlugin.install(app, { queryClient })
  registerMatomo(app, router)

  app.mount('#app')
}

main()
